import React from 'react';
import './App.scss';

function App() {
  return (
    <div className="Glitch" data-text="hoobyt">hoobyt</div>
  );
}

export default App;
